.App {
 width: 100vw;
 height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: #080b3f;
}

.logo-container {
 position: fixed;
 z-index: 1000;
 margin-left: -53px;
}

.logo {
 width: 100%;
 max-width: 360px;
 height: auto;
}

.btn {
 background-color: #4e52a2;
 color: #fff;
 padding: 10px 20px;
 border-radius: 5px;
 text-decoration: none;
 font-weight: 700;
}

.btn-container {
 margin-top: 20px;
 display: flex;
 justify-content: center;
 margin-right: -106px;

 @media screen and (max-width: 768px) {
  margin-right: 0;
 }
}

.logo-wrapper {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 1rem;
}

.domain-text {
 color: #fff;
 font-size: 5rem;
 font-family: Mollen;
 font-weight: 700;
 position: absolute;
 right: -106px;
 bottom: -87px;
}

/* Dölj .se på mobila enheter */
@media screen and (max-width: 768px) {
 .domain-text {
  display: none;
 }
}
